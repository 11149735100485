// App.js
import React, { useRef, useEffect, useState } from 'react';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import './App.css'; // Import the CSS file
import ReactPlayer from 'react-player';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

function App() {
  const containerRef = useRef(null);

  const scrollToCTA = () => {
    const ctaPanel = document.getElementById('cta-panel');
    if (ctaPanel) {
      ctaPanel.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const element = containerRef.current;
    element.style.scrollBehavior = 'smooth';
  }, []);

  const panels = [
    {
      src: './assets/landscape-clothes.mp4',
      title: 'Welcome to Flipify',
      description:
        'Connecting buyers and sellers in a seamless marketplace. Discover a new way to buy and sell.',
      orientation: 'landscape',
    },
    {
      src: './assets/portrait-taking-picture-of-item.mp4',
      title: 'Effortless Listing Creation',
      description:
        'With Flipify AI, you can focus on showcasing your items while we handle the research.',
      orientation: 'portrait',
    },
    {
      src: './assets/portrait-item.mp4',
      title: 'Discover Great Deals',
      description:
        'Connect with sellers offering exactly what you’re looking for.',
      orientation: 'portrait',
    },
    {
      src: './assets/garbage.mp4',
      title: 'Reduce Waste, Earn Cash',
      description:
        'Millions of belongings are simply thrown away. Flipify automatically values your items and posts them, ensuring they reach the right buyers.',
      orientation: 'landscape',
    },
    {
      src: './assets/cta-video.mp4',
      title: 'Join the Flipify Community',
      description:
        'Download the app now and start connecting with buyers and sellers today!',
      isCTA: true,
    },
  ];

  return (
    <Router>
      <Routes>
        <Route path="/item/:itemId" element={<ItemRedirect />} />
        <Route path="/" element={
          <div className="App" ref={containerRef}>
            <header className="app-header">
              <h1 onClick={scrollToCTA}>Download Flipify</h1>
            </header>
            {panels.map((panel, index) => (
              <VideoPanel
                key={index}
                panel={panel}
                isLastPanel={index === panels.length - 1}
                id={panel.isCTA ? 'cta-panel' : null}
              />
            ))}
          </div>
        } />
      </Routes>
    </Router>
  );
}

function ItemRedirect() {
  const { itemId } = useParams();

  useEffect(() => {
    const appUrl = `flipify://item/${itemId}`;
    window.location.href = appUrl;
  }, [itemId]);

  return (
    <div style={{ 
      background: 'linear-gradient(135deg, #8e2de2, #4a00e0)', 
      color: 'white', 
      textAlign: 'center', 
      padding: '60px 30px', 
      borderRadius: '15px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      maxWidth: '500px',
      margin: '50px auto'
    }}>
      <meta property="og:title" content="Flipify Item" />
      <meta property="og:description" content="Come check out this item on flipify!" />
      <meta property="og:image" content="logo.jpg" />
      <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>🚀 Redirecting to the Flipify app...</h2>
      <p style={{ fontSize: '16px', lineHeight: '1.5' }}>
        Please wait while we take you to the item page in the Flipify app. If you are not redirected automatically, please ensure the app is installed on your device.
      </p>
    </div>
  );
}

function VideoPanel({ panel, isLastPanel, id }) {
  const panelRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  // Intersection Observer to detect visibility
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.6, // Adjust as needed
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    const currentPanel = panelRef.current;
    if (currentPanel) {
      observer.observe(currentPanel);
    }

    return () => {
      if (currentPanel) {
        observer.unobserve(currentPanel);
      }
    };
  }, []);

  // Set videoLoaded to true when the panel becomes visible
  useEffect(() => {
    if (isVisible && !videoLoaded) {
      setVideoLoaded(true);
    }
  }, [isVisible, videoLoaded]);

  return (
    <div
      className={`panel video-panel ${panel.orientation}`}
      ref={panelRef}
      id={id}
    >
      {videoLoaded ? (
        <ReactPlayer
          url={panel.src}
          playing={isVisible}
          loop
          muted
          width="100%"
          height="100%"
          className="full-video"
          playsinline
        />
      ) : (
        <div className="video-placeholder">
          {/* Placeholder content or a poster image */}
        </div>
      )}
      <div className={`overlay-text ${panel.isCTA ? '' : 'blurred'}`}>
        <h2>{panel.title}</h2>
        <p>{panel.description}</p>
        {panel.isCTA && (
          <div className="download-buttons">
            <a
              href="https://play.google.com/store"
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              <FaGooglePlay /> Google Play
            </a>
            <a
              href="https://www.apple.com/app-store/"
              target="_blank"
              rel="noopener noreferrer"
              className="download-button"
            >
              <FaApple /> App Store
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
